<template>
  <div class="committed-container">
    <header>
      <el-button type="primary" size="small" @click="open('edit')" v-if="isAdmin">编辑</el-button>
    </header>
    <div class="committed-content">
      <div class="content" v-html="content" v-show="content">
      </div>
      <div class="content" v-show="!content" style="text-align:center;color:#fff;">
        暂无内容
      </div>
    </div>
    <el-dialog title="编辑告知书" :visible.sync="committedShow" width="50%" v-dialogDrag :close-on-click-modal="false">
      <editor-bar v-model="editContent" :isClear="isClear" @change="changeContent"></editor-bar>
      <span>
        <el-button @click="committedShow = false">取 消</el-button>
        <el-button type="primary" @click="editCommitted">编 辑</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import EditorBar from '../../../../components/Editor'
export default {
  props: {
    companyId: {
      type: Number,
      default: 0
    },
    isAdmin: {
      type: Boolean,
      default: true
    }
  },
  components: {
    EditorBar
  },
  data () {
    return {
      content: '',
      editContent: '',
      committedShow: false,
      isClear: false,
      committedId: ''
    }
  },
  created () {
    this.initCommitted()
  },
  methods: {
    open (flag) {
      switch (flag) {
        case 'edit':
          this.editContent = this.content
          this.committedShow = true
      }
    },
    async initCommitted () {
      const data = {}
      if (this.companyId !== 0) {
        data.unitId = this.companyId
      }
      const { data: result } = await this.$axios.get('/fireUnitCommit/list', { params: data })
      if (result.code === 200) {
        this.committedId = result.data[0].id
        this.content = result.data[0].content
      }
    },
    async editCommitted () {
      const { data: result } = await this.$axios.put(`/fireUnitCommit/edit/${this.committedId}`, { content: this.editContent })
      if (result.code === 200) {
        this.$message.success('编辑成功')
        this.initCommitted()
        this.committedShow = false
      } else {
        this.$message.error(result.message)
      }
    },
    changeContent (val) {

    }
  }
}
</script>

<style lang="scss" scoped>
.committed-container {
  width: 100%;
  height: 100%;
  header {
    display: flex;
    justify-content: flex-start;
  }
  .committed-content {
    margin: 30px auto;
    padding: 50px 100px;
    width: 800px;
    border: 1px solid #e2e2e2;
    box-sizing: border-box;
  }
}
</style>
